<script lang="ts" setup>
import { Collapse } from 'bootstrap'
import { computed, onMounted } from 'vue'
import { AnalysisType } from '~/src/Domain/Engagement/AnalysisType'
import type Engagement from '~/src/Domain/Engagement/Engagement'
import type Phase from '~/src/Domain/Engagement/Phase'
import type User from '~/src/Domain/Identity/User'
import type Organisation from '~/src/Domain/Organisation/Organisation'
import useAccountsPayableNavigation from '~/src/UserInterface/AccountsPayable/composables/useAccountsPayableNavigation'
import useAccountsReceivableNavigation from '~/src/UserInterface/AccountsReceivable/composables/useAccountsReceivableNavigation'
import useUI from '~/src/UserInterface/App/composables/useUi'
import useBankNavigation from '~/src/UserInterface/Bank/composables/useBankNavigation'
import useDataCubeState from '~/src/UserInterface/DataCube/composables/useDataCubeState'
import usePhaseTypeTranslation
  from '~/src/UserInterface/Engagement/composables/usePhaseTypeTranslation'
import useGeneralLedgerNavigation from '~/src/UserInterface/GeneralLedger/composables/useGeneralLedgerNavigation'
import useWorkProgramNavigation from '~/src/UserInterface/WorkProgram/composables/useWorkProgramNavigation'

const props = defineProps<{
  user: User
  organisation: Organisation
  engagement: Engagement
  phase: Phase
}>()

const { dataCubeCollection } = useDataCubeState()
const { toggleOrganisationOffCanvas } = useUI()

onMounted(() => {
  const collapseElementList = document.querySelectorAll('.collapse') ?? []
  const _ = [...collapseElementList].map((collapseEl) => new Collapse(collapseEl, {
    toggle: collapseEl.id === 'general-ledger-collapse',
  }))
})

const organisation = computed(() => props.organisation)
const engagement = computed(() => props.engagement)
const phase = computed(() => props.phase)

const {
  generalLedgerIndexLocalePath,
  generalLedgerOverviewLocalePath,
  generalLedgerMasterDataChartsOfAccountsLocalePath,
  generalLedgerGlValidationLocalePath,
  generalLedgerJournalEntriesLocalePath,
  generalLedgerEnteredDatesLocalePath,
  generalLedgerEnteredTimesLocalePath,
  generalLedgerDaysLagLocalePath,
  generalLedgerSourcesExplorerLocalePath,
  generalLedgerPreparersExplorerLocalePath,
  generalLedgerBusinessUnitsExplorerLocalePath,
  generalLedgerOtherSideOfJournalEntriesLocalePath,
  generalLedgerTwoWayCorrelationsLocalePath,
} = useGeneralLedgerNavigation(organisation, engagement, phase)

const haveAllGeneralLedgerDataCubesBeenPopulated = computed(() =>
  dataCubeCollection.value.haveAllDataCubesBeenPopulatedFor(AnalysisType.GENERAL_LEDGER, props.phase['@id']),
)

const {
  bankIndexLocalePath,
  bankOverviewLocalePath,
  bankStatementEntryValidationLocalePath,
  bankStatementGeneralLedgerReconciliationLocalePath,
  bankStatementEntryDetailsLocalePath,
  bankStatementEntryDetailCorruptionPerceptionIndexLocalePath,
  bankStatementEntryDetailMultipleBankAccountsLocalePath,
  bankStatementEntryDetailMultipleCustomersLocalePath,
  bankStatementEntryDetailsExplorerLocalePath,
} = useBankNavigation(organisation, engagement, phase)

const haveAllBankDataCubesBeenPopulated = computed(() =>
  dataCubeCollection.value.haveAllDataCubesBeenPopulatedFor(AnalysisType.BANK, props.phase['@id']),
)

const {
  accountsPayableIndexLocalePath,
  accountsPayableMasterDataLocalePath,
  accountsPayableOverviewLocalePath,
  accountsPayableMultipleSupplierNamesSameBankAccountLocalePath,
  accountsPayableMultipleBankAccountsSameSupplierNameLocalePath,
  accountsPayableExplorerLocalePath,
} = useAccountsPayableNavigation(organisation, engagement, phase)

const haveAllAccountsPayableDataCubesBeenPopulated = computed(() =>
  dataCubeCollection.value.haveAllDataCubesBeenPopulatedFor(AnalysisType.ACCOUNTS_PAYABLE, props.phase['@id']),
)

const {
  accountsReceivableIndexLocalePath,
  accountsReceivableMasterDataLocalePath,
  accountsReceivableOverviewLocalePath,
  accountsReceivableExplorerLocalePath,
} = useAccountsReceivableNavigation(organisation, engagement, phase)

const haveAllAccountsReceivableDataCubesBeenPopulated = computed(() =>
  dataCubeCollection.value.haveAllDataCubesBeenPopulatedFor(AnalysisType.ACCOUNTS_RECEIVABLE, props.phase['@id']),
)

const { workProgramOverviewLocalePath } = useWorkProgramNavigation(organisation, engagement, phase)
</script>

<template>
  <ui-navbar
    id="sidebar"
    toggleable="md"
    class="navbar-vertical fixed-start navbar-light"
  >
    <ui-navbar-toggle target="sidebarCollapse" />

    <ui-navbar-brand class="text-center p-2 pb-0 cursor-pointer">
      <ui-img
        :src="organisation.logo"
        :alt="organisation.name"
        style="max-height: 3rem"
        class="mb-3"
      />
    </ui-navbar-brand>

    <ui-img
      :src="organisation.logo"
      :alt="organisation.name"
      style="max-height: 3rem"
      class="mb-3 d-md-none"
    />

    <ui-button
      variant="white"
      class="text-center text-wrap w-100 mb-3"
      @click="toggleOrganisationOffCanvas"
    >
      {{ engagement.name }}
    </ui-button>

    <ui-dropdown
      variant="white"
      toggle-class="w-100"
      size="sm"
      @click="toggleOrganisationOffCanvas"
    >
      <template #button-content>
        <span class="fst-italic">
          {{ usePhaseTypeTranslation(phase.type) }}
        </span>
      </template>
      <engagement-switch-engagement-dropdown-item
        v-for="availablePhase in engagement.phases"
        :key="availablePhase['@id'].toString()"
        :organisation
        :engagement
        :phase="availablePhase"
        :current-phase="phase['@id']"
      />
    </ui-dropdown>

    <identity-avatar-dropdown
      :user
      class="d-md-none"
    />

    <ui-collapse
      id="sidebarCollapse"
      is-nav
    >
      <ui-navbar-divider />

      <ui-navbar-nav>
        <ui-nav-item
          v-if="!haveAllGeneralLedgerDataCubesBeenPopulated"
          :to="generalLedgerIndexLocalePath"
        >
          <ui-icon icon="journal-text" />
          {{ $t('components.layout.sidebar.nav_general_ledger') }}
        </ui-nav-item>

        <div class="nav-item">
          <ui-link
            v-if="haveAllGeneralLedgerDataCubesBeenPopulated"
            class="nav-link"
            data-bs-target="#general-ledger-collapse"
            data-bs-toggle="collapse"
            role="button"
            tabindex="0"
          >
            <ui-icon icon="journal-text" />
            {{ $t('components.layout.sidebar.nav_general_ledger') }}
          </ui-link>

          <ui-collapse
            v-if="haveAllGeneralLedgerDataCubesBeenPopulated"
            id="general-ledger-collapse"
            data-bs-parent="#sidebarCollapse"
          >
            <ui-nav class="nav-sm flex-column">
              <ui-nav-item :to="generalLedgerOverviewLocalePath">
                {{ $t('components.layout.sidebar.nav_overview') }}
              </ui-nav-item>

              <div class="nav-item">
                <ui-link
                  class="nav-link"
                  data-bs-target="#general-ledger-master-data-collapse"
                  data-bs-toggle="collapse"
                  role="button"
                  tabindex="0"
                >
                  {{ $t('components.layout.sidebar.nav_master_data') }}
                </ui-link>

                <ui-collapse
                  id="general-ledger-master-data-collapse"
                  data-bs-parent="#general-ledger-collapse"
                >
                  <ui-nav class="nav-sm flex-column">
                    <ui-nav-item :to="generalLedgerMasterDataChartsOfAccountsLocalePath">
                      {{ $t('components.layout.sidebar.nav_chart_of_accounts') }}
                    </ui-nav-item>
                  </ui-nav>
                </ui-collapse>
              </div>

              <ui-nav-item :to="generalLedgerGlValidationLocalePath">
                {{ $t('components.layout.sidebar.nav_validation') }}
              </ui-nav-item>
              <ui-nav-item :to="generalLedgerJournalEntriesLocalePath">
                {{ $t('components.layout.sidebar.nav_journal_entries') }}
              </ui-nav-item>

              <div class="nav-item">
                <ui-link
                  class="nav-link"
                  data-bs-target="#gl-timing-collapse"
                  data-bs-toggle="collapse"
                  role="button"
                  tabindex="0"
                >
                  {{ $t('components.layout.sidebar.nav_timing') }}
                </ui-link>

                <ui-collapse
                  id="gl-timing-collapse"
                  data-bs-parent="#general-ledger-collapse"
                >
                  <ui-nav class="nav-sm flex-column">
                    <ui-nav-item :to="generalLedgerEnteredDatesLocalePath">
                      {{ $t('components.layout.sidebar.nav_entered_date') }}
                    </ui-nav-item>
                    <ui-nav-item :to="generalLedgerEnteredTimesLocalePath">
                      {{ $t('components.layout.sidebar.nav_entered_time') }}
                    </ui-nav-item>
                    <ui-nav-item :to="generalLedgerDaysLagLocalePath">
                      {{ $t('components.layout.sidebar.nav_days_lag') }}
                    </ui-nav-item>
                  </ui-nav>
                </ui-collapse>
              </div>

              <div class="nav-item">
                <ui-link
                  class="nav-link"
                  data-bs-target="#gl-explorer-collapse"
                  data-bs-toggle="collapse"
                  role="button"
                  tabindex="0"
                >
                  {{ $t('components.layout.sidebar.nav_explorers') }}
                </ui-link>

                <ui-collapse
                  id="gl-explorer-collapse"
                  data-bs-parent="#general-ledger-collapse"
                >
                  <ui-nav class="nav-sm flex-column">
                    <ui-nav-item :to="generalLedgerSourcesExplorerLocalePath">
                      {{ $t('components.layout.sidebar.nav_source_explorers') }}
                    </ui-nav-item>
                    <ui-nav-item :to="generalLedgerPreparersExplorerLocalePath">
                      {{ $t('components.layout.sidebar.nav_preparer_explorers') }}
                    </ui-nav-item>
                    <ui-nav-item :to="generalLedgerBusinessUnitsExplorerLocalePath">
                      {{ $t('components.layout.sidebar.nav_business_unit_explorers') }}
                    </ui-nav-item>
                  </ui-nav>
                </ui-collapse>
              </div>

              <div class="nav-item">
                <ui-link
                  class="nav-link"
                  data-bs-target="#gl-booking-pattern"
                  data-bs-toggle="collapse"
                  role="button"
                  tabindex="0"
                >
                  {{ $t('components.layout.sidebar.nav_booking_patterns') }}
                </ui-link>

                <ui-collapse
                  id="gl-booking-pattern"
                  data-bs-parent="#general-ledger-collapse"
                >
                  <ui-nav class="nav-sm flex-column">
                    <ui-nav-item :to="generalLedgerOtherSideOfJournalEntriesLocalePath">
                      {{ $t('components.layout.sidebar.nav_other_side_of_je') }}
                    </ui-nav-item>
                    <ui-nav-item :to="generalLedgerTwoWayCorrelationsLocalePath">
                      {{ $t('components.layout.sidebar.nav_two_way_correlation') }}
                    </ui-nav-item>
                  </ui-nav>
                </ui-collapse>
              </div>
            </ui-nav>
          </ui-collapse>
        </div>
      </ui-navbar-nav>

      <ui-navbar-divider />

      <ui-navbar-nav>
        <ui-nav-item
          v-if="!haveAllBankDataCubesBeenPopulated"
          :to="bankIndexLocalePath"
        >
          <ui-icon icon="bank" />
          {{ $t('components.layout.sidebar.nav_bank') }}
        </ui-nav-item>

        <div class="nav-item">
          <ui-link
            v-if="haveAllBankDataCubesBeenPopulated"
            class="nav-link"
            data-bs-target="#bank-collapse"
            data-bs-toggle="collapse"
            role="button"
            tabindex="0"
          >
            <ui-icon icon="bank" />
            {{ $t('components.layout.sidebar.nav_bank') }}
          </ui-link>

          <ui-collapse
            v-if="haveAllBankDataCubesBeenPopulated"
            id="bank-collapse"
            data-bs-parent="#sidebarCollapse"
          >
            <ui-nav class="nav-sm flex-column">
              <ui-nav-item :to="bankOverviewLocalePath">
                {{ $t('components.layout.sidebar.nav_overview') }}
              </ui-nav-item>
              <ui-nav-item :to="bankStatementEntryValidationLocalePath">
                {{ $t('components.layout.sidebar.nav_validation') }}
              </ui-nav-item>
              <ui-nav-item :to="bankStatementGeneralLedgerReconciliationLocalePath">
                {{ $t('components.layout.sidebar.nav_bank_general_ledger_reconciliation') }}
              </ui-nav-item>
              <ui-nav-item :to="bankStatementEntryDetailsLocalePath">
                {{ $t('components.layout.sidebar.nav_bank_statements') }}
              </ui-nav-item>
              <ui-nav-item :to="bankStatementEntryDetailCorruptionPerceptionIndexLocalePath">
                {{
                  $t(
                    'components.layout.sidebar.nav_bank_statement_entry_details_corruption_perception_indices',
                  )
                }}
              </ui-nav-item>
              <ui-nav-item :to="bankStatementEntryDetailMultipleBankAccountsLocalePath">
                {{
                  $t(
                    'components.layout.sidebar.nav_bank_statement_entry_detail_multiple_bank_accounts',
                  )
                }}
              </ui-nav-item>
              <ui-nav-item :to="bankStatementEntryDetailMultipleCustomersLocalePath">
                {{
                  $t(
                    'components.layout.sidebar.nav_bank_statement_entry_detail_multiple_counter_parties',
                  )
                }}
              </ui-nav-item>

              <div class="nav-item">
                <ui-link
                  class="nav-link"
                  data-bs-target="#bank-explorers"
                  data-bs-toggle="collapse"
                  role="button"
                  tabindex="0"
                >
                  {{ $t('components.layout.sidebar.nav_explorers') }}
                </ui-link>

                <ui-collapse id="bank-explorers">
                  <ui-nav class="nav-sm flex-column">
                    <ui-nav-item :to="bankStatementEntryDetailsExplorerLocalePath">
                      {{ $t('components.layout.sidebar.nav_bank_statements') }}
                    </ui-nav-item>
                  </ui-nav>
                </ui-collapse>
              </div>
            </ui-nav>
          </ui-collapse>
        </div>
      </ui-navbar-nav>

      <ui-navbar-divider />

      <ui-navbar-nav>
        <ui-nav-item
          v-if="!haveAllAccountsPayableDataCubesBeenPopulated"
          :to="accountsPayableIndexLocalePath"
        >
          <ui-icon
            icon="database-fill-up"
            class="me-2"
          />
          {{ $t('components.layout.sidebar.nav_accounts_payable') }}
        </ui-nav-item>

        <div class="nav-item">
          <ui-link
            v-if="haveAllAccountsPayableDataCubesBeenPopulated"
            class="nav-link"
            data-bs-target="#accounts-payable-collapse"
            data-bs-toggle="collapse"
            role="button"
            tabindex="0"
          >
            <ui-icon icon="database-fill-up" />
            {{ $t('components.layout.sidebar.nav_accounts_payable') }}
          </ui-link>

          <ui-collapse
            v-if="haveAllAccountsPayableDataCubesBeenPopulated"
            id="accounts-payable-collapse"
            data-bs-parent="#sidebarCollapse"
          >
            <ui-nav class="nav-sm flex-column">
              <div class="nav-item">
                <ui-link
                  class="nav-link"
                  data-bs-target="#accounts-payable-master-data-collapse"
                  data-bs-toggle="collapse"
                  role="button"
                  tabindex="0"
                >
                  {{ $t('components.layout.sidebar.nav_master_data') }}
                </ui-link>

                <ui-collapse
                  id="accounts-payable-master-data-collapse"
                  data-bs-parent="#accounts-payable-collapse"
                >
                  <ui-nav class="nav-sm flex-column">
                    <ui-nav-item :to="accountsPayableMasterDataLocalePath">
                      {{
                        $t('components.layout.sidebar.nav_accounts_payable_creditor_master_data')
                      }}
                    </ui-nav-item>
                  </ui-nav>
                </ui-collapse>
              </div>
              <ui-nav-item :to="accountsPayableOverviewLocalePath">
                {{ $t('components.layout.sidebar.nav_accounts_payable_outstanding') }}
              </ui-nav-item>
              <ui-nav-item :to="accountsPayableMultipleBankAccountsSameSupplierNameLocalePath">
                {{
                  $t(
                    'components.layout.sidebar.nav_accounts_payable_multiple_bank_accounts_same_supplier_name',
                  )
                }}
              </ui-nav-item>
              <ui-nav-item :to="accountsPayableMultipleSupplierNamesSameBankAccountLocalePath">
                {{
                  $t(
                    'components.layout.sidebar.nav_accounts_payable_multiple_supplier_names_same_bank_account',
                  )
                }}
              </ui-nav-item>

              <div class="nav-item">
                <ui-link
                  class="nav-link"
                  data-bs-target="#accounts-payable-explorer"
                  data-bs-toggle="collapse"
                  role="button"
                  tabindex="0"
                >
                  {{ $t('components.layout.sidebar.nav_explorers') }}
                </ui-link>

                <ui-collapse id="accounts-payable-explorer">
                  <ui-nav class="nav-sm flex-column">
                    <ui-nav-item :to="accountsPayableExplorerLocalePath">
                      {{ $t('components.layout.sidebar.nav_accounts_payable_explorer') }}
                    </ui-nav-item>
                  </ui-nav>
                </ui-collapse>
              </div>
            </ui-nav>
          </ui-collapse>
        </div>
      </ui-navbar-nav>

      <ui-navbar-divider />

      <ui-navbar-nav>
        <ui-nav-item
          v-if="!haveAllAccountsReceivableDataCubesBeenPopulated"
          :to="accountsReceivableIndexLocalePath"
        >
          <ui-icon
            icon="database-fill-up"
            class="me-2"
          />
          {{ $t('components.layout.sidebar.nav_accounts_receivable') }}
        </ui-nav-item>

        <div class="nav-item">
          <ui-link
            v-if="haveAllAccountsReceivableDataCubesBeenPopulated"
            class="nav-link"
            data-bs-target="#accounts-receivable-collapse"
            data-bs-toggle="collapse"
            role="button"
            tabindex="0"
          >
            <ui-icon
              icon="database-fill-up"
              class="me-2"
            />
            {{ $t('components.layout.sidebar.nav_accounts_receivable') }}
          </ui-link>

          <ui-collapse
            v-if="haveAllAccountsReceivableDataCubesBeenPopulated"
            id="accounts-receivable-collapse"
            data-bs-parent="#sidebarCollapse"
          >
            <ui-nav class="nav-sm flex-column">
              <div class="nav-item">
                <ui-link
                  class="nav-link"
                  data-bs-target="#accounts-reveivable-master-data-collapse"
                  data-bs-toggle="collapse"
                  role="button"
                  tabindex="0"
                >
                  {{ $t('components.layout.sidebar.nav_master_data') }}
                </ui-link>

                <ui-collapse
                  id="accounts-reveivable-master-data-collapse"
                  data-bs-parent="#accounts-receivable-collapse"
                >
                  <ui-nav class="nav-sm flex-column">
                    <ui-nav-item :to="accountsReceivableMasterDataLocalePath">
                      {{
                        $t('components.layout.sidebar.nav_accounts_receivable_debtor_master_data')
                      }}
                    </ui-nav-item>
                  </ui-nav>
                </ui-collapse>
              </div>

              <ui-nav-item :to="accountsReceivableOverviewLocalePath">
                {{ $t('components.layout.sidebar.nav_accounts_receivable_outstanding') }}
              </ui-nav-item>

              <div class="nav-item">
                <ui-link
                  class="nav-link"
                  data-bs-target="#accounts-receivable-explorer"
                  data-bs-toggle="collapse"
                  role="button"
                  tabindex="0"
                >
                  {{ $t('components.layout.sidebar.nav_explorers') }}
                </ui-link>

                <ui-collapse id="accounts-receivable-explorer">
                  <ui-nav class="nav-sm flex-column">
                    <ui-nav-item :to="accountsReceivableExplorerLocalePath">
                      {{ $t('components.layout.sidebar.nav_accounts_receivable_explorer') }}
                    </ui-nav-item>
                  </ui-nav>
                </ui-collapse>
              </div>
            </ui-nav>
          </ui-collapse>
        </div>
      </ui-navbar-nav>

      <ui-navbar-divider />

      <ui-navbar-heading>
        {{ $t('components.layout.sidebar.nav_audit_programs') }}
      </ui-navbar-heading>

      <ui-navbar-nav>
        <ui-nav-item :to="workProgramOverviewLocalePath">
          {{ $t('components.layout.sidebar.nav_workprogram_overview') }}
        </ui-nav-item>
      </ui-navbar-nav>
    </ui-collapse>

    <ui-navbar-user class="d-none d-md-flex mt-4">
      <notifications-button />
      <identity-avatar-dropdown :user />
      <intl-language-select-popover dropup>
        <template #button-text>
          <ui-icon
            icon="globe"
            class="fs-2"
          />
        </template>
      </intl-language-select-popover>
    </ui-navbar-user>
  </ui-navbar>
</template>

<style lang="scss">
#sidebar {
  .navbar-nav .nav-link > i {
    min-width: 1.75rem;
  }
}
</style>
