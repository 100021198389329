<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import type Engagement from '~/src/Domain/Engagement/Engagement'
import type Phase from '~/src/Domain/Engagement/Phase'
import type PhaseIri from '~/src/Domain/Engagement/PhaseIri'
import type Organisation from '~/src/Domain/Organisation/Organisation'
import useDataCubeState from '~/src/UserInterface/DataCube/composables/useDataCubeState'
import useAnalysisTypeTranslation from '~/src/UserInterface/Engagement/composables/useAnalysisTypeTranslation'
import useEngagementNavigation from '~/src/UserInterface/Engagement/composables/useEngagementNavigation'
import usePhaseTypeTranslation from '~/src/UserInterface/Engagement/composables/usePhaseTypeTranslation'
import useWorkProgramNavigation from '~/src/UserInterface/WorkProgram/composables/useWorkProgramNavigation'

const props = defineProps<{
  organisation: Organisation
  engagement: Engagement
  phase: Phase
  currentPhase: PhaseIri
}>()

const { route, getEngagementLocalePath, engagementDashboardLocalePath } = useEngagementNavigation(
  computed(() => props.organisation),
  computed(() => props.engagement),
  computed(() => props.phase),
)
const { workProgramOverviewLocalePath } = useWorkProgramNavigation(
  computed(() => props.organisation),
  computed(() => props.engagement),
  computed(() => props.phase),
)

const switchPath = ref('/')
watch(
  [
    () => route,
    engagementDashboardLocalePath,
    workProgramOverviewLocalePath,
  ],
  ([newRoute, newEngagementDashboardPath, newWorkProgramOverviewPath]) => {
    const routeName = newRoute.name?.toString().split('___')[0]
    if ('workProgramSlug' in newRoute.params) {
      switchPath.value = newWorkProgramOverviewPath
    } else if (routeName !== undefined && 'phaseId' in newRoute.params) {
      switchPath.value = getEngagementLocalePath(routeName)
    } else {
      switchPath.value = newEngagementDashboardPath
    }
  },
  {
    deep: true,
    immediate: true,
  },
)

const { dataCubeCollection } = useDataCubeState()
const hasAnalysisTypes = computed(() => props.phase.hasAnalysisTypes())
const phaseIsCurrent = computed(() => props.phase['@id'].equalsTo(props.currentPhase))
</script>

<template>
  <ui-dropdown-item
    :disabled="!hasAnalysisTypes || phaseIsCurrent"
    :class="{
      'border-0': !phaseIsCurrent,
      'border-2': phaseIsCurrent,
      'border-primary': phaseIsCurrent,
      'border-start': phaseIsCurrent,
    }"
    size="sm"
    :to="switchPath"
  >
    <div class="d-flex">
      <span
        :class="{
          'fw-lighter': !hasAnalysisTypes,
        }"
      >
        {{ usePhaseTypeTranslation(phase.type) }}
      </span>

      <div
        v-if="hasAnalysisTypes"
        class="ms-auto"
      >
        <ui-badge
          v-for="analysisType in phase.analysisTypes"
          :key="analysisType"
          class="ms-2"
          :variant="dataCubeCollection.haveAllDataCubesBeenPopulatedFor(analysisType, phase['@id']) ? 'success' : 'warning'"
          small
        >
          {{ useAnalysisTypeTranslation(analysisType) }}
        </ui-badge>
      </div>
      <div
        v-else
        class="ms-auto"
      >
        <ui-icon
          icon="bi-slash-circle"
          :class="{
            'fw-lighter': !hasAnalysisTypes,
          }"
        />
      </div>
    </div>
  </ui-dropdown-item>
</template>
